import { httpService } from '../../http/http';
import batchRequestFactory from '../batchRequestService/batchRequestFactory';
import {
  getBatchMetricsUrl,
  Measure,
  MetricsResult,
  DefaultBatchSize,
  DefaultProcessBatchWaitTime
} from './metricsConstants';
import metricsMetaData from './metricsMetaData';

let idCounter = 0;
const tasks: Map<number, Measure> = new Map();
const {
  performanceMetricsBatchWaitTime,
  performanceMetricsBatchSize
} = metricsMetaData.getMetaData();
const metricsBatchRequestProcessor = batchRequestFactory.createRequestProcessor(
  items => {
    const urlConfig = {
      url: getBatchMetricsUrl(),
      retryable: true,
      withCredentials: true
    };
    const measures = items.map(({ itemId }) => tasks.get(itemId));
    return httpService.post(urlConfig, measures).then(() => {
      const results: MetricsResult = {};
      items.forEach(({ itemId }) => {
        results[itemId] = true;
        tasks.delete(itemId);
      });
      return results;
    });
  },
  id => id?.toString(),
  {
    batchSize: performanceMetricsBatchSize || DefaultBatchSize,
    processBatchWaitTime: performanceMetricsBatchWaitTime || DefaultProcessBatchWaitTime
  }
);

export default {
  logMeasurement: (measure: Measure) => {
    const taskId = idCounter;
    idCounter += 1;
    tasks.set(taskId, measure);
    return metricsBatchRequestProcessor.queueItem(taskId);
  }
};
